import logo from './logo.svg';
import './App.css';



function App() {

  return (
    
    <div className="App">
   
      <div>
        
        <h1>Fun Puzzle</h1>
        <div>Simple and fun puzzle apps and word learning apps. </div>

        <h1>Published Apps</h1>
        <div>
         <img src="/numCroAppLogo.png" width={100} height={100}/><div>NumCro: Numbered Crossword</div>
        </div>
        <div>
         <img src="/icon_pinyin.png" width={100} height={100}/><div>Chinese Pinyin Bootcamp</div>
        </div>
        <div>
         <img src="/appLogo.png" width={100} height={100}/><div>Deep AI Professor Reversi</div>
        </div>
        <div>
         <img src="/calcLogo.png" width={100} height={100}/><div>Memory Math Game 100</div>
        </div>
        <div>
         <img src="/crosswordLogo.png" width={100} height={100}/><div>Japanese Crossword 365</div>
        </div>
       
        
    </div>
    <div>
        <h1 class="sectionTitle">Privacy Policies</h1>
        <div class="bodyText">
        Followings are privacy policies for using Fun Puzzle apps. 
        </div>
        <h2 class="sectionTitle"> 1. Data to be Collected</h2>
        
        <h3 class="sectionTitle"> Personal data collected via technology when using the apps</h3>
        <div class="bodyText">
        Fun Puzzle apps may collect users' personal data automatically while using the apps. Those data are used for (A) Analytics: to improve the apps, fix errors/bugs, prevent frauds and monitor the efficiency of advertisement, and for (B) In-app Advertisement: to provide more relevant advertisements to the users. Those data would be shared with and processed by some of third party partners that are listed below, through their SDKs implemented in the apps.
        </div>
        
        <ul class="list">
            <li> (A) Analytics Partners (Click link for their privacy policies) </li>
              <ul>
                  <li> <a href="https://firebase.google.com/support/privacy">Google Analytics for Firebase</a></li>
                  <li> Google Crashlytics</li>
                  <li> Unity</li>
                  <li> <a href="https://www.adjust.com/terms/privacy-policy/">Adjust</a></li>
              </ul>
          
            <li> (B) In-app Advertisement Partners </li>
              <ul>
                  <li> <a href="https://policies.google.com/privacy">Google Admob</a></li>
                  <li> <a href="https://developers.is.com/ironsource-mobile/air/ironsource-mobile-privacy-policy/">ironSource</a></li>
                  <li> <a href="https://www.applovin.com/privacy/">AppLovin</a></li>
                  <li> <a href="https://unity3d.com/legal/privacy-policy">Unity</a></li>
                  <li> <a href="https://www.pangleglobal.com/privacy/enduser-en">Pangle</a></li>
                  <li> <a href="https://vungle.com/privacy/">Vungle</a></li>
                  <li> <a href="https://www.tapjoy.com/legal/players/privacy-policy/">Tapjoy</a></li>
                  <li> <a href="https://www.facebook.com/privacy/explanation">Meta Audience Network</a></li>
                
              </ul>
        </ul>
        
        <div class="bodyText">
        Following personal data may be gathered when using the apps.
        </div>

        <ul class="list">
          <li>Approximate location (ex. which city you are in)</li>
          <li>Device ID (Android advertising ID, app set ID)</li>
          <li>Device Information (ex. device type, screen size, telcom company, language setting, operating system, battery status) </li>
          <li>Crash or error logs</li>
          <li>App interactions (ex. opening, button clicks, quiz completions)</li>
          <li>App install/uninstall</li>
        
        </ul>

        <div class="bodyText">
        Following links provide detailed information about how the 3rd party partners gather the personal data mentioned above.
        </div>
        <ul class="list">
            <li> <a href="https://firebase.google.com/docs/android/play-data-disclosure">Google Firebase</a></li>
            <li> <a href="https://developers.google.com/admob/android/play-data-disclosure">Google Admob</a></li>            
            <li> Google Crashlytics</li>   
            <li> <a href="https://developers.is.com/ironsource-mobile/general/google-data-safety-questionnaire/">ironSource</a></li>
            <li> <a href="https://docs.unity.com/analytics/GoogleDataSafety.html">Unity Ads</a></li>
            <li> <a href="https://www.applovin.com/privacy/">AppLovin</a></li>
            <li> <a href="https://www.pangleglobal.com/privacy">Pangle</a></li>
            <li> Vungle</li>
            <li> Adjust</li>
            <li> Tapjoy</li>
            <li> Meta Autience Network</li>
        </ul>
          
        <h3 class="sectionTitle"> Personal data provided through feedback or contact</h3>
        <div class="bodyText">
        If you provided us with feedback or contact us in email or other ways, we may collect some of your personal data, such as email address, first and last name and other data you volunteerly provided. Those data are used for better support.
        </div>

        <h2 class="sectionTitle"> 2. Disclosure to Third Parties</h2>
        <div class="bodyText">
        The data above are not shared or disclosed to third parties except for following cases:
        </div>

        <ul class="list">
          <li>Sharing to the partners (Analytics and Advertisement) mentioned above</li>
          <li>Sharing as anonymous statistical data that show general usage patterns</li>
          <li>Sharing as a process of business/app transfer or termination</li>
          <li>To comply with the law, and to protect the rights, property or safety of us or others</li>
        </ul>


        <h2 class="sectionTitle"> 3. Data Retention</h2>
        <div class="bodyText">
        We ratain the data above only for as long as necessary to fulfill the purposes the data are collected for.
        </div>

        <h2 class="sectionTitle"> 4. Changing of the Policies</h2>
        <div class="bodyText">
        To improve the apps and to comply with changing regulations, this Privacy Policy may change from time to time.
        </div>

        <h2 class="sectionTitle"> 5.Others</h2>
        <div class="bodyText">
        These apps are not intended for children.
        </div>
           
        <h2 class="sectionTitle"> 6.Contact</h2>
        <div class="bodyText">
        Email:  funpuzzleapp@gmail.com
        </div>
        <div class="bodyText">
        Last update: Aug 20 2022 
        </div>
        
       
     </div>
    </div>
  );
}

export default App;
